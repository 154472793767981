import {
    applyMiddleware,
    compose,
    legacy_createStore as createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { reducers } from './../reducers';
import { setLanguage } from '../actions/language';

//TODO: redux-persist to store redux state even after page refresh

// const reduxDevTools =
//     window.__REDUX_DEVTOOLS_EXTENSION__ &&
//     window.__REDUX_DEVTOOLS_EXTENSION__();

export const store = createStore(
    reducers,
    compose(applyMiddleware(thunk, loadingBarMiddleware()))
);

// subscribe
// store.subscribe(() => console.log(store.getState()));

//initialize
store.dispatch(setLanguage(process.env.REACT_APP_LANGUAGE || 'en'));
