import { getPublicToken } from './../services/forgeService';
import { errorMessage } from './message';
import locale from './../utils/localization.json';

export const getAccessToken = async (callback) => {
    try {
        const { data } = await getPublicToken();
        callback(data.access_token, data.expires_in);
    } catch (err) {
        // log.error(err);
    }
};

export const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'Failed to load manifest [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    if (viewerErrorCode === 9) {
        errorMessage([lang].onDocumentLoadFailureErrorCode9);
    } else {
        errorMessage(locale[lang].onDocumentLoadFailureOtherErrors);
    }
};

export const onLoadModelError = (viewerErrorCode, viewerErrorMsg) => {
    console.error(
        'onLoadModelError() [' + viewerErrorCode + '] ' + viewerErrorMsg
    );
    const lang = localStorage.getItem('language');
    errorMessage(locale[lang].onLoadModelError);
};

export const addModel = (urn, viewer) => {
    return new Promise((resolve, reject) => {
        window.Autodesk.Viewing.Document.load(
            `urn:${urn.replace('_', '/')}`,
            (doc) => {
                const geometries = doc.getRoot().search({ type: 'geometry' });
                const initGeom = geometries[0];
                const svfUrl = doc.getViewablePath(initGeom);
                viewer?.loadModel(
                    svfUrl,
                    {
                        createWireframe: false,
                        applyScaling: 'm',
                        isAEC: true,
                        globalOffset: viewer?.model?.getGlobalOffset(),
                        acmSessionId: doc.getAcmSessionId(svfUrl),
                        // applyRefPoint: true,
                    },
                    (model) => resolve(model),
                    onLoadModelError
                );
                if (viewer === undefined || viewer === null) reject();
            }
        );
    });
};

export const removeModel = (model, viewer) => {
    viewer?.unloadModel(model);
};

export const handleTransparency = (viewer, dbIds, elements, value) => {
    var fragList = viewer?.model?.getFragmentList();
    viewer?.model?.unconsolidate();
    dbIds
        .filter((i) => !elements.includes(i))
        .forEach((dbId) => {
            const fragIds = nodeIdToFragIds(viewer?.model, dbId);
            fragIds.forEach((fragId) => {
                let material = fragList.getMaterial(fragId).clone();
                if (material) {
                    material.opacity = value;
                    material.transparent = true;
                    material.needsUpdate = true;
                }
                viewer?.impl
                    .matman()
                    .addMaterial('myCustomMaterial-' + fragId, material, true);
                viewer?.model?.getFragmentList().setMaterial(fragId, material);

                viewer?.impl?.invalidate(true);
            });
        });
};

const nodeIdToFragIds = (model, nodeId) => {
    var instanceTree = model.getData().instanceTree;

    var fragIds = [];
    instanceTree.enumNodeFragments(nodeId, (fragId) => {
        fragIds = [...fragIds, fragId];
    });

    return fragIds;
};

export const overrideOpacity = (viewer, dbIds) => {
    const fragList = viewer?.model?.getFragmentList();

    dbIds.forEach((dbId) => {
        const fragIds = nodeIdToFragIds(viewer?.model, dbId);

        fragIds.forEach((fragId) => {
            const material = fragList.getMaterial(fragId);

            if (material) {
                material.opacity = 0.1;
                material.transparent = true;
                material.needsUpdate = true;
            }
        });
    });
    viewer?.impl?.invalidate(true);
    viewer?.model?.unconsolidate();

    return true;
};

export const getAllLeafComponents = (viewer, callback) => {
    if (viewer)
        viewer?.getObjectTree(function (tree) {
            let leaves = [];
            tree.enumNodeChildren(
                tree.getRootId(),
                function (dbId) {
                    if (tree.getChildCount(dbId) === 0) {
                        leaves.push(dbId);
                    }
                },
                true
            );

            callback(leaves);
        });
};

export const getProjectBasePoint = async (model) => {
    try {
        const basePointData = await getBasePointData(model);

        const eastWestProp = basePointData?.properties?.find(
            (p) => p.attributeName === 'E/W'
        );
        const northSouthProp = basePointData?.properties?.find(
            (p) => p.attributeName === 'N/S'
        );
        const elevProp = basePointData?.properties?.find(
            (p) => p.attributeName === 'Elev'
        );

        // eslint-disable-next-line no-undef
        const eastWestVal = Autodesk.Viewing.Private.convertToDisplayUnits(
            eastWestProp.displayValue,
            eastWestProp.type,
            eastWestProp.units,
            model.getUnitString()
        );
        // eslint-disable-next-line no-undef
        const northSouthVal = Autodesk.Viewing.Private.convertToDisplayUnits(
            northSouthProp.displayValue,
            northSouthProp.type,
            northSouthProp.units,
            model.getUnitString()
        );
        // eslint-disable-next-line no-undef
        const elevVal = Autodesk.Viewing.Private.convertToDisplayUnits(
            elevProp.displayValue,
            elevProp.type,
            elevProp.units,
            model.getUnitString()
        );

        // eslint-disable-next-line no-undef
        const basePoint = new THREE.Vector3(
            eastWestVal.displayValue,
            northSouthVal.displayValue,
            elevVal.displayValue
        );

        return basePoint;
    } catch {
        return null;
    }
};

const getBasePointData = async (model, category = 'Revit Base Point') => {
    return new Promise(async (resolve, reject) => {
        try {
            const found = await searchAsync(model, category, ['Category'], {
                searchHidden: true,
            });
            if (!found || found.length <= 0)
                return reject('Base point not found');

            const result = await getBulkProperties2Async(
                found,
                { propFilter: ['N/S', 'E/W', 'Elev', 'Angle to True North'] },
                model
            );

            if (!result) return reject('Base point not found');

            const data = result[0];
            return resolve(data);
        } catch (err) {
            reject(err);
        }
    });
};

const searchAsync = async (model, text, attributeNames, options) => {
    return new Promise((resolve, reject) => {
        model.search(text, resolve, reject, attributeNames, options);
    });
};

const getBulkProperties2Async = async (dbIds, options, model) => {
    return new Promise((resolve, reject) => {
        model.getBulkProperties2(
            dbIds,
            options,
            (result) => resolve(result),
            (error) => reject(error)
        );
    });
};

export const restoreSectionBox = (viewer, cutplanes) => {
    let box = new window.THREE.Box3();
    for (const cutplane of cutplanes) {
        const normal = new window.THREE.Vector3(
            cutplane[0],
            cutplane[1],
            cutplane[2]
        );
        const offset = cutplane[3];
        const pointOnPlane = normal.clone().negate().multiplyScalar(offset);
        box.expandByPoint(pointOnPlane);
    }
    const sectionExt = viewer.getExtension('Autodesk.Section');
    sectionExt.setSectionBox(box);
};

export const restoreSectionPlane = (viewer, cutplane) => {
    const normal = new window.THREE.Vector3(
        cutplane[0],
        cutplane[1],
        cutplane[2]
    );
    const offset = cutplane[3];
    const pointOnPlane = normal.clone().negate().multiplyScalar(offset);

    const sectionExt = viewer.getExtension('Autodesk.Section');
    sectionExt.setSectionPlane(normal, pointOnPlane);
};
