import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import socketIO from 'socket.io-client';

import PrivateLayout from './../components/Layouts/PrivateLayout';
import Models from './../components/Models/Models';
import MainContext from './../components/context/MainContext.jsx';
import Viewer from '../components/Viewer/Viewer';
import { handleModelTranslate } from './../actions/model';
import locale from './../utils/localization.json';
import NotFound from '../components/common/NotFound';
import PageRedirector from './../components/common/PageRedirector';

const Bimodel = () => {
    const currentObject = useSelector((state) => state.currentObject);
    const { permissions } = useSelector((state) => state.currentProject);
    const lang = useSelector((state) => state.language);

    const dispatch = useDispatch();

    useEffect(() => {
        const socket = socketIO(`${process.env.REACT_APP_OSS_SOCKET}/socket`, {
            transports: ['websocket'],
        });
        socket.on('update', ({ _id, versions }) => {
            dispatch(handleModelTranslate(_id, versions));
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Switch>
            <Route path={['/project', '/viewer']}>
                <MainContext>
                    <PrivateLayout>
                        <Switch>
                            <Route
                                path="/project/models"
                                exact
                                render={() =>
                                    permissions?.model?.download ? (
                                        <Models title={locale[lang].models} />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                }
                            />
                            <Route
                                path="/project/pointclouds"
                                exact
                                render={() =>
                                    permissions?.point_cloud?.download ? (
                                        <Models
                                            title={locale[lang].pointClouds}
                                        />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                }
                            />
                            <Route
                                path="/viewer"
                                render={() =>
                                    !isEmpty(currentObject) ? (
                                        <Viewer name={currentObject.name} />
                                    ) : (
                                        <Redirect to="/" />
                                    )
                                }
                            />
                            <Route
                                path="*"
                                render={() => <Redirect to="/not-found" />}
                            />
                        </Switch>
                    </PrivateLayout>
                </MainContext>
            </Route>
            <Route path="/not-found" component={NotFound} />
            <Route
                path="/"
                exact
                render={() =>
                    permissions?.model?.download ? (
                        <Redirect to="/project/models" />
                    ) : permissions?.point_cloud?.download ? (
                        <Redirect to="/project/pointclouds" />
                    ) : (
                        <PageRedirector />
                    )
                }
            />
            <Route path="*" render={() => <Redirect to="/not-found" />} />
        </Switch>
    );
};

export default Bimodel;
