import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getDate, getTime } from './../../utils/date';
import { getIcon } from './../../utils/fileTypes';
import { addModel, removeModel } from './../../utils/forge';
import {
    IcAdd,
    IcAddFill,
    IcEye,
    IcEyeFill,
    IcObjectDate,
    IcObjectName,
    IcObjectVersion,
    IcRemove,
    IcRemoveFill,
    IcDelete,
    IcDeleteFill,
    IcDownload,
    IcLoading,
} from './../../share/icons';
import { mainContext } from './../context/mainContext';
import locale from '../../utils/localization.json';

const ObjectCard = ({ object, isModel }) => {
    const { urn, name, type, date, version, url } = object;

    const lang = useSelector((state) => state.language);
    const viewer = useSelector((state) => state.viewer);

    const [waiting, setWaiting] = useState(false);
    const [, forceUpdate] = useState();

    const {
        loadModel,
        handlePointClouds,
        currentLinks,
        setCurrentLinks,
        loadedPointClouds,
        deletePointCloud,
    } = useContext(mainContext);

    useEffect(() => () => forceUpdate(true), [loadedPointClouds]);

    return (
        <div className="w-full h-28 pr-4 pl-2 py-4 flex flex-col justify-between bg-modelic-gray-800 rounded border border-modelic-gray-700">
            <div className="flex justify-between">
                <div className="max-w-[calc(100%-57px)] flex-col inline-flex gap-2">
                    <div className="justify-start items-center gap-1.5 inline-flex">
                        <div className="text-modelic-primary-lighter">
                            {IcObjectName}
                        </div>
                        <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3">
                            {locale[lang].name}:
                        </div>
                        <div className="truncate text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                            {name}.{type}
                        </div>
                    </div>
                    <div className="justify-start items-center gap-1.5 inline-flex">
                        <div className="text-modelic-primary-lighter">
                            {IcObjectDate}
                        </div>
                        <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3">
                            {locale[lang].date}:
                        </div>
                        <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                            {getDate(date)} - {getTime(date)}
                        </div>
                    </div>
                </div>
                <div>{getIcon(type)}</div>
            </div>
            <div
                className={`flex ${
                    isModel ||
                    (loadedPointClouds?.find(
                        (p) => p.name === name && p.type === type
                    ) &&
                        loadedPointClouds?.find(
                            (p) => p.name === name && p.type === type
                        ).isLoaded === undefined)
                        ? 'justify-between'
                        : 'justify-end'
                }`}
            >
                {isModel ? (
                    <div className="justify-start items-center gap-1.5 inline-flex">
                        <div className="text-modelic-primary-lighter">
                            {IcObjectVersion}
                        </div>
                        <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3">
                            {locale[lang].version}:
                        </div>
                        <div className="px-2 pt-1 pb-0.5 bg-modelic-info-darker rounded-lg border border-modelic-info-main">
                            <div className="text-modelic-info-main text-xs font-normal font-poppins content-end">
                                V{version}
                            </div>
                        </div>
                    </div>
                ) : (
                    loadedPointClouds?.find(
                        (p) => p.name === name && p.type === type
                    ) &&
                    loadedPointClouds?.find(
                        (p) => p.name === name && p.type === type
                    ).isLoaded === undefined && (
                        <div className="justify-start items-center inline-flex">
                            <div className="text-modelic-primary-lighter scale-90 mr-1.5">
                                {IcDownload}
                            </div>
                            <div className="text-modelic-primary-lighter text-xs font-normal font-poppins leading-3 mr-1.5">
                                {locale[lang].progress}:
                            </div>
                            <div className="text-modelic-gray-100 text-xs font-medium font-poppins leading-3">
                                {loadedPointClouds?.find(
                                    (p) => p.name === name && p.type === type
                                ).progress || 0}{' '}
                                %
                            </div>
                            <div className="scale-50 text-modelic-primary-main">
                                <div className="animate-spin">{IcLoading}</div>
                            </div>
                        </div>
                    )
                )}
                <div
                    className={`flex gap-4 ${
                        loadedPointClouds?.find(
                            (p) => p.name === name && p.type === type
                        ) &&
                        loadedPointClouds?.find(
                            (p) => p.name === name && p.type === type
                        ).isLoaded === undefined
                            ? ''
                            : 'self-end'
                    }`}
                >
                    {isModel && (
                        <button
                            className={`text-modelic-primary-lighter ${
                                !waiting ? 'group hover:scale-105' : ''
                            }`}
                            disabled={isModel && waiting}
                            onClick={async () => {
                                if (isModel) setWaiting(true);

                                try {
                                    loadModel(viewer, object, 0);
                                } finally {
                                    setWaiting(false);
                                }
                            }}
                        >
                            <>
                                {IcEye}
                                {IcEyeFill}
                            </>
                        </button>
                    )}
                    {viewer?.model?.getDocumentNode()?.data?.role === '3d' && (
                        <>
                            {loadedPointClouds?.find(
                                (p) => p.name === name && p.type === type
                            )?.isLoaded !== undefined && (
                                <button
                                    className="group hover:scale-105 text-modelic-error-dark"
                                    onClick={() => deletePointCloud(name, type)}
                                >
                                    <>
                                        {IcDelete}
                                        {IcDeleteFill}
                                    </>
                                </button>
                            )}
                            <button
                                className={`font-poppins text-xs font-medium ${
                                    !waiting ? 'group hover:scale-105' : ''
                                } ${
                                    currentLinks?.find(
                                        (link) => link.urn === urn
                                    ) ||
                                    loadedPointClouds?.find(
                                        (p) =>
                                            p.name === name && p.type === type
                                    )?.isLoaded
                                        ? 'text-modelic-error-dark'
                                        : loadedPointClouds?.find(
                                              (p) =>
                                                  p.name === name &&
                                                  p.type === type
                                          ) &&
                                          loadedPointClouds?.find(
                                              (p) =>
                                                  p.name === name &&
                                                  p.type === type
                                          ).isLoaded === undefined
                                        ? 'text-modelic-gray-500 '
                                        : 'text-modelic-primary-main'
                                }`}
                                disabled={isModel && waiting}
                                onClick={async () => {
                                    try {
                                        if (isModel) {
                                            setWaiting(true);
                                            const linkIndex =
                                                currentLinks.findIndex(
                                                    (link) => link.urn === urn
                                                );

                                            if (linkIndex >= 0) {
                                                removeModel(
                                                    currentLinks[linkIndex]
                                                        .model,
                                                    viewer
                                                );
                                                const filteredLinks =
                                                    currentLinks.filter(
                                                        (link) =>
                                                            link.urn !== urn
                                                    );

                                                setCurrentLinks([
                                                    ...filteredLinks,
                                                ]);
                                            } else {
                                                const model = await addModel(
                                                    urn,
                                                    viewer
                                                );

                                                setCurrentLinks([
                                                    ...currentLinks,
                                                    { urn, model },
                                                ]);
                                            }
                                        } else {
                                            await handlePointClouds(
                                                name,
                                                type,
                                                url
                                            );
                                        }
                                    } catch {
                                    } finally {
                                        setWaiting(false);
                                    }
                                }}
                            >
                                {currentLinks?.find(
                                    (link) => link.urn === urn
                                ) ||
                                loadedPointClouds?.find(
                                    (p) => p.name === name && p.type === type
                                )?.isLoaded ? (
                                    <>
                                        {IcRemove}
                                        {IcRemoveFill}
                                    </>
                                ) : loadedPointClouds?.find(
                                      (p) => p.name === name && p.type === type
                                  ) &&
                                  loadedPointClouds?.find(
                                      (p) => p.name === name && p.type === type
                                  ).isLoaded === undefined ? (
                                    locale[lang].stop
                                ) : (
                                    <>
                                        {IcAdd}
                                        {IcAddFill}
                                    </>
                                )}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ObjectCard;
